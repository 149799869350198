<template>
    <div class="approval">
        <div class="approval_box">
            <div style="background: #fff; padding: 14px 34px 20px 20px">
                <div class="title">
                    {{ data.adminName ? data.adminName : '- -' }} 提交的报销申请
                </div>
                <div class="content">
                    <p>
                        <span>所在部门</span>
                        <span>
                            <el-tooltip placement="top"
                                >·
                                <div slot="content">
                                    <p
                                        style="line-height: 2"
                                        v-for="item in data.departmentAdminDOList"
                                        :key="item.id"
                                    >
                                        {{ item.departmentName }}
                                    </p>
                                </div>
                                <em style="font-style: normal">
                                    {{ returnName(data) }}
                                </em>
                            </el-tooltip></span
                        >
                    </p>
                    <p>
                        <span>提交日期</span
                        ><span>{{ $tableDataHandle.createTime(data) }}</span>
                    </p>
                </div>
                <div class="content">
                    <p>
                        <span>客户</span
                        ><span v-if="data.customer">{{
                            data.customer.companyName || '- -'
                        }}</span>
                    </p>
                </div>
                <div class="content">
                    <p>
                        <span>客户名称</span
                        ><span>{{ data.customerName || '- -' }}</span>
                    </p>
                </div>
                <div class="content">
                    <p>
                        <span>报销金额</span
                        ><span>{{ data.expenseAmount }}元</span>
                    </p>
                    <p>
                        <span>报销状态</span
                        ><span>{{
                            $tableDataHandle.reimbStatus(data.status)
                        }}</span>
                    </p>
                </div>
                <div class="content">
                    <p class="declare_box">
                        <span>关联申报</span>
                        <span
                            class="declare"
                            @click="onDeclare()"
                            v-if="data.customer && data.businessDeclare"
                            >{{ data.customer.companyName }}
                            的
                            {{ typeStr }}申报</span
                        >
                        <span v-else>- -</span>
                    </p>
                </div>
                <div
                    class="title_two"
                    v-if="
                        data.expenseDetailList &&
                        data.expenseDetailList.length > 0
                    "
                >
                    报销凭证
                </div>
                <div
                    v-if="
                        data.expenseDetailList &&
                        data.expenseDetailList.length > 0
                    "
                    v-for="item in data.expenseDetailList"
                >
                    <div class="content">
                        <p>
                            <span>备注</span>
                            <span>{{ item.remark }}</span>
                        </p>
                    </div>
                    <div class="content">
                        <p class="voucher">
                            <span>支付凭证</span>
                            <span
                                v-for="itm in item.expenseDetailAttachmentBOList"
                                :key="itm.attachmentUrl"
                            >
                                <el-image
                                    v-if="
                                        itm.attachmentUrl.indexOf('.jpg') !=
                                            -1 ||
                                        itm.attachmentUrl.indexOf('.png') !=
                                            -1 ||
                                        itm.attachmentUrl.indexOf('.gif') != -1
                                    "
                                    style="width: 82px; height: 82px"
                                    :src="itm.attachmentUrl"
                                    :preview-src-list="[itm.attachmentUrl]"
                                >
                                </el-image>
                                <span
                                    class="pdf"
                                    v-if="
                                        itm.attachmentUrl.indexOf('.pdf') !=
                                            -1 ||
                                        itm.attachmentUrl.indexOf('.PDF') != -1
                                    "
                                    @click="onPreview(itm.attachmentUrl)"
                                    style="cursor: pointer"
                                >
                                    <img src="@/img/pdfUrl.png" alt=""
                                /></span>
                                <span
                                    class="word"
                                    v-if="
                                        itm.attachmentUrl.indexOf('.doc') != -1
                                    "
                                    @click="onPreview(itm.attachmentUrl)"
                                    style="cursor: pointer"
                                >
                                    <img src="@/img/word.png" alt="" />
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    class="title_three"
                    v-if="
                        data.expenseAuditList &&
                        data.expenseAuditList.length > 0
                    "
                >
                    审批
                </div>
                <div
                    class="audit_remark"
                    v-if="
                        data.expenseAuditList &&
                        data.expenseAuditList.length > 0
                    "
                >
                    <p v-for="item in data.expenseAuditList" :key="item.id">
                        <i
                            :class="
                                item.status == 1
                                    ? 'toast_title'
                                    : item.status == 0
                                    ? 'toast_title delete'
                                    : 'toast_title pay'
                            "
                            >{{
                                item.status == 1
                                    ? '通过'
                                    : item.status == 0
                                    ? '驳回'
                                    : '支付'
                            }}</i
                        >
                        <span
                            >审批备注-{{ item.adminName }} <br />
                            <i>{{ item.createTime }}</i>
                        </span>
                        <em>{{ item.remark }}</em>
                    </p>
                </div>
            </div>
            <div style="min-height: 10px; background: #f0f0f0"></div>
            <div class="comment">
                <div v-for="item in commentData" style="padding-bottom: 20px">
                    <p style="padding-bottom: 6px; font-size: 14px">
                        <span style="color: #2370eb"
                            ><i
                                class="el-icon-user-solid"
                                style="color: #999999; margin-right: 8px"
                            ></i
                            >{{ item.adminName }}</span
                        >
                        <span style="color: #666666">
                            {{ item.createTime }}</span
                        >
                    </p>
                    <p class="comment_text">
                        <span>{{ item.comment }}</span>
                        <el-button
                            v-if="item.adminId == adminId"
                            @click="onDelete(item)"
                            type="text"
                            icon="el-icon-delete"
                            style="color: #da5120"
                        ></el-button>
                    </p>
                </div>
                <div style="display: flex">
                    <el-input
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="comment"
                    >
                    </el-input>
                    <el-button
                        @click="onComment"
                        type="primary"
                        style="
                            background: #2370eb;
                            border: none;
                            margin-left: 8px;
                        "
                        >发送评论</el-button
                    >
                </div>
            </div>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                type="primary"
                style="background: #2370eb; padding: 7px 20px"
                round
                @click="onSubmit"
                size="mini"
                >{{ subVal }}</el-button
            >
        </div>
    </div>
</template>
<script>
import { declareGet } from '@/api/cost/declare';
import {
    reimbAudit,
    addComment,
    commentList,
    commentDelete,
} from '@/api/reimb/reimb';
export default {
    data() {
        return {
            data: {},
            status: '1',
            remark: '',
            subVal: '确 定',
            typeStr: '',
            userType: sessionStorage.getItem('userType'),
            commentData: [],
            comment: '',
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    methods: {
        getData(row) {
            console.log(row);
            this.data = row;
            // console.log(this.data);
            if (this.data.declareId) {
                this.declareGet(this.data.declareId);
            }
            if (this.userType == 15) {
                if (row.status == 2) {
                    this.subVal = '审 批';
                } else {
                    this.subVal = '确 定';
                }
            }
            this.getCommentList(row.id);
        },
        onClose() {
            this.$emit('close');
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },

        onSubmit() {
            if (this.subVal == '确 定') {
                this.onClose();
            } else {
                this.$emit('onAudit', this.data);
            }
        },
        declareGet(id) {
            this.typeStr = '';
            let data = {
                param: {
                    id,
                },
            };
            declareGet(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    res.data.declareDetailList.forEach((item, index) => {
                        if (index == res.data.declareDetailList.length - 1) {
                            this.typeStr += this.$cost.declareDetailType(
                                item.type
                            );
                        } else {
                            this.typeStr +=
                                this.$cost.declareDetailType(item.type) + '、';
                        }
                    });
                }
            });
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + url
                );
            } else {
                window.open(url, '_blank');
            }
        },
        onDeclare() {
            this.$emit('onDeclares', this.data);
            this.onClose();
        },
        getCommentList(id) {
            let data = {
                param: {
                    expenseId: id,
                },
            };
            commentList(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.commentData = res.data.list;
                }
            });
        },
        onComment() {
            let data = {
                param: {
                    expenseId: this.data.id,
                    comment: this.comment,
                },
            };
            if (!this.comment) {
                return this.$message.error('请输入评论');
            }
            addComment(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.comment = '';
                    this.getCommentList(this.data.id);
                }
            });
        },
        onDelete(item) {
            let data = {
                param: {
                    id: item.id,
                },
            };
            commentDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getCommentList(this.data.id);
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.approval {
    height: 100%;
    display: flex;
    flex-direction: column;
    .approval_box {
        overflow: auto;
        flex: 1;
    }
    .comment {
        background: #fff;
        margin-top: 10px;
        padding: 14px 34px 20px 20px;
    }
    .comment_text {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
    }
    .title {
        font-weight: 500;
        color: #2370eb;
        font-size: 14px;
        margin-bottom: 8px;
    }
    .title_two {
        font-weight: 500;
        color: #ff8a00;
        font-size: 14px;
        margin-bottom: 8px;
        margin-top: 20px;
    }
    .title_three {
        font-weight: 500;
        color: #50cc1d;
        font-size: 14px;
        margin-bottom: 8px;
        margin-top: 20px;
    }
    .content {
        display: flex;
        padding: 8px 0;
        font-size: 14px;
        color: #666666;

        p {
            flex: 1;
            span:nth-child(1) {
                margin-right: 12px;
                width: 70px;
                display: inline-block;
            }
            span:nth-child(2) {
                color: #333333;
                font-weight: 600;
            }
        }
    }
    .declare_box {
        display: flex;
    }
    .declare {
        width: 240px;
        border-radius: 2px;
        border: 1px dashed #2370eb;
        display: inline-block;
        padding: 3px 20px;
        color: #2370eb;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .circle {
            font-size: 16px;
            margin-left: auto;
            cursor: pointer;
        }
    }
    .voucher {
        display: flex;
        align-items: top;
    }
}
.bottom-but {
    width: 480px;
    line-height: 48px;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    right: 0;
    button {
        width: 90px;
        z-index: 1000;
    }
}
.audit_remark {
    p {
        display: flex;
        font-size: 14px;
        color: #333333;
        display: flex;
        position: relative;
        margin-bottom: 16px;
        padding-left: 35px;
        span {
            color: #666666;
            display: block;
            flex: 1;
            white-space: nowrap;
            i {
                font-size: 12px;
                color: #999;
                font-style: normal;
            }
        }
        em {
            flex: 1;
            font-style: normal;
        }
    }
    .toast_title {
        display: block;
        width: 56px;
        height: 28px;
        position: absolute;
        top: -5px;
        left: -14px;
        background: rgba(35, 112, 235, 0.07);
        border-radius: 1px;
        border: 1px solid #2370eb;
        font-size: 18px;
        text-align: center;
        transform: scale(0.5);
        color: #2370eb;
        font-style: normal;
    }
    .delete {
        font-style: normal;
        left: -14px;
        background: rgba(208, 2, 27, 0.05);
        border-radius: 1px;
        border: 1px solid #d0021b;
        color: #d0021b;
        cursor: pointer;
    }
    .pay {
        font-style: normal;
        left: -14px;
        background: #02d04b0d;
        border-radius: 1px;
        border: 1px solid #50cc1d;
        color: #50cc1d;
        cursor: pointer;
    }
    p:before {
        content: '';
        position: absolute;
        left: 15px;
        top: -17px;
        height: 18px;
        border-left: 1px dashed #eff4fd;
    }
    :first-child:before {
        height: 0;
    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"approval"},[_c('div',{staticClass:"approval_box"},[_c('div',{staticStyle:{"background":"#fff","padding":"14px 34px 20px 20px"}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.data.adminName ? _vm.data.adminName : '- -')+" 提交的报销申请 ")]),_c('div',{staticClass:"content"},[_c('p',[_c('span',[_vm._v("所在部门")]),_c('span',[_c('el-tooltip',{attrs:{"placement":"top"}},[_vm._v("· "),_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.data.departmentAdminDOList),function(item){return _c('p',{key:item.id,staticStyle:{"line-height":"2"}},[_vm._v(" "+_vm._s(item.departmentName)+" ")])}),0),_c('em',{staticStyle:{"font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.returnName(_vm.data))+" ")])])],1)]),_c('p',[_c('span',[_vm._v("提交日期")]),_c('span',[_vm._v(_vm._s(_vm.$tableDataHandle.createTime(_vm.data)))])])]),_c('div',{staticClass:"content"},[_c('p',[_c('span',[_vm._v("客户")]),(_vm.data.customer)?_c('span',[_vm._v(_vm._s(_vm.data.customer.companyName || '- -'))]):_vm._e()])]),_c('div',{staticClass:"content"},[_c('p',[_c('span',[_vm._v("客户名称")]),_c('span',[_vm._v(_vm._s(_vm.data.customerName || '- -'))])])]),_c('div',{staticClass:"content"},[_c('p',[_c('span',[_vm._v("报销金额")]),_c('span',[_vm._v(_vm._s(_vm.data.expenseAmount)+"元")])]),_c('p',[_c('span',[_vm._v("报销状态")]),_c('span',[_vm._v(_vm._s(_vm.$tableDataHandle.reimbStatus(_vm.data.status)))])])]),_c('div',{staticClass:"content"},[_c('p',{staticClass:"declare_box"},[_c('span',[_vm._v("关联申报")]),(_vm.data.customer && _vm.data.businessDeclare)?_c('span',{staticClass:"declare",on:{"click":function($event){return _vm.onDeclare()}}},[_vm._v(_vm._s(_vm.data.customer.companyName)+" 的 "+_vm._s(_vm.typeStr)+"申报")]):_c('span',[_vm._v("- -")])])]),(
                    _vm.data.expenseDetailList &&
                    _vm.data.expenseDetailList.length > 0
                )?_c('div',{staticClass:"title_two"},[_vm._v(" 报销凭证 ")]):_vm._e(),_vm._l((_vm.data.expenseDetailList),function(item){return (
                    _vm.data.expenseDetailList &&
                    _vm.data.expenseDetailList.length > 0
                )?_c('div',[_c('div',{staticClass:"content"},[_c('p',[_c('span',[_vm._v("备注")]),_c('span',[_vm._v(_vm._s(item.remark))])])]),_c('div',{staticClass:"content"},[_c('p',{staticClass:"voucher"},[_c('span',[_vm._v("支付凭证")]),_vm._l((item.expenseDetailAttachmentBOList),function(itm){return _c('span',{key:itm.attachmentUrl},[(
                                    itm.attachmentUrl.indexOf('.jpg') !=
                                        -1 ||
                                    itm.attachmentUrl.indexOf('.png') !=
                                        -1 ||
                                    itm.attachmentUrl.indexOf('.gif') != -1
                                )?_c('el-image',{staticStyle:{"width":"82px","height":"82px"},attrs:{"src":itm.attachmentUrl,"preview-src-list":[itm.attachmentUrl]}}):_vm._e(),(
                                    itm.attachmentUrl.indexOf('.pdf') !=
                                        -1 ||
                                    itm.attachmentUrl.indexOf('.PDF') != -1
                                )?_c('span',{staticClass:"pdf",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(itm.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(
                                    itm.attachmentUrl.indexOf('.doc') != -1
                                )?_c('span',{staticClass:"word",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(itm.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e()],1)})],2)])]):_vm._e()}),(
                    _vm.data.expenseAuditList &&
                    _vm.data.expenseAuditList.length > 0
                )?_c('div',{staticClass:"title_three"},[_vm._v(" 审批 ")]):_vm._e(),(
                    _vm.data.expenseAuditList &&
                    _vm.data.expenseAuditList.length > 0
                )?_c('div',{staticClass:"audit_remark"},_vm._l((_vm.data.expenseAuditList),function(item){return _c('p',{key:item.id},[_c('i',{class:item.status == 1
                                ? 'toast_title'
                                : item.status == 0
                                ? 'toast_title delete'
                                : 'toast_title pay'},[_vm._v(_vm._s(item.status == 1 ? '通过' : item.status == 0 ? '驳回' : '支付'))]),_c('span',[_vm._v("审批备注-"+_vm._s(item.adminName)+" "),_c('br'),_c('i',[_vm._v(_vm._s(item.createTime))])]),_c('em',[_vm._v(_vm._s(item.remark))])])}),0):_vm._e()],2),_c('div',{staticStyle:{"min-height":"10px","background":"#f0f0f0"}}),_c('div',{staticClass:"comment"},[_vm._l((_vm.commentData),function(item){return _c('div',{staticStyle:{"padding-bottom":"20px"}},[_c('p',{staticStyle:{"padding-bottom":"6px","font-size":"14px"}},[_c('span',{staticStyle:{"color":"#2370eb"}},[_c('i',{staticClass:"el-icon-user-solid",staticStyle:{"color":"#999999","margin-right":"8px"}}),_vm._v(_vm._s(item.adminName))]),_c('span',{staticStyle:{"color":"#666666"}},[_vm._v(" "+_vm._s(item.createTime))])]),_c('p',{staticClass:"comment_text"},[_c('span',[_vm._v(_vm._s(item.comment))]),(item.adminId == _vm.adminId)?_c('el-button',{staticStyle:{"color":"#da5120"},attrs:{"type":"text","icon":"el-icon-delete"},on:{"click":function($event){return _vm.onDelete(item)}}}):_vm._e()],1)])}),_c('div',{staticStyle:{"display":"flex"}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入内容"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('el-button',{staticStyle:{"background":"#2370eb","border":"none","margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.onComment}},[_vm._v("发送评论")])],1)],2)]),_c('div',{staticClass:"bottom-but"},[_c('el-button',{staticStyle:{"background":"none","border-color":"#d5d6df","color":"#666666","margin-left":"270px"},attrs:{"round":"","type":"primary","size":"mini"},on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"background":"#2370eb","padding":"7px 20px"},attrs:{"type":"primary","round":"","size":"mini"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.subVal))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column label="提交日期" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{ $tableDataHandle.createTime(scope.row) }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报销部门" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <el-tooltip placement="top"
                        >·
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <em style="font-style: normal">
                            {{ returnName(scope.row) }}
                        </em>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="报销人"
                show-overflow-tooltip
                prop="adminName"
                width="60"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报销状态" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ $cost.reimbStatus(scope.row.status) }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户名称" show-overflow-tooltip width="193">
                <template slot-scope="scope">
                    <el-button
                        @click="onDetails(scope.row)"
                        style="color: #2370eb"
                        type="text"
                    >
                        {{ scope.row.customerName || '- -' }}
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报销金额" width="120" prop="expenseAmount">
                <template slot-scope="scope">
                    <span>{{ scope.row.expenseAmount }}元</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="备注"
                prop=""
                width="173"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.expenseDetailList &&
                            scope.row.expenseDetailList.length > 0
                        "
                        >{{ scope.row.expenseDetailList[0].remark }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="关联申报" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.businessDeclare"
                        @click="onDeclareDetails(scope.row)"
                        style="color: #2370eb"
                        type="text"
                    >
                        详情
                    </el-button>

                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="报销类型" width="80" prop="expenseAmount">
                <template slot-scope="scope">
                    <span>{{ $cost.expenseType(scope.row.expenseType) }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="支付凭证" width="160" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div
                        v-if="
                            scope.row.expenseDetailList &&
                            scope.row.expenseDetailList.length > 0 &&
                            scope.row.expenseDetailList[0]
                                .expenseDetailAttachmentBOList &&
                            scope.row.expenseDetailList[0]
                                .expenseDetailAttachmentBOList.length == 1
                        "
                    >
                        <span
                            style="color: #2370eb; cursor: pointer"
                            @click="
                                openFile(
                                    scope.row.expenseDetailList[0]
                                        .expenseDetailAttachmentBOList[0]
                                        .attachmentUrl,
                                    scope.row.expenseDetailList[0].expenseDetailAttachmentBOList[0].attachmentName.split(
                                        '.'
                                    )[1]
                                )
                            "
                        >
                            {{
                                scope.row.expenseDetailList[0]
                                    .expenseDetailAttachmentBOList[0]
                                    .attachmentName
                            }}
                        </span>
                    </div>
                    <div
                        v-else-if="
                            scope.row.expenseDetailList &&
                            scope.row.expenseDetailList.length > 0 &&
                            scope.row.expenseDetailList[0]
                                .expenseDetailAttachmentBOList &&
                            scope.row.expenseDetailList[0]
                                .expenseDetailAttachmentBOList.length > 1
                        "
                        style="
                            color: #2370eb;
                            cursor: pointer;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        "
                    >
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Bottom Center 提示文字"
                            placement="bottom"
                        >
                            <div slot="content">
                                <p
                                    class="file_list"
                                    v-for="item in scope.row
                                        .expenseDetailList[0]
                                        .expenseDetailAttachmentBOList"
                                    :key="item.attachmentUrl"
                                    @click="
                                        openFile(
                                            item.attachmentUrl,
                                            item.attachmentName.split('.')[1]
                                        )
                                    "
                                >
                                    {{ item.attachmentName }}
                                </p>
                            </div>
                            <span>多个凭证</span>
                        </el-tooltip>
                    </div>
                    <div v-else>- -</div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="操作"
                fixed="right"
                v-if="routePath != '/reimbList'"
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="routePath == '/reimbAudit' && btnP.audit"
                        @click="onApproval(scope.row)"
                        style="color: #2370eb"
                        type="text"
                    >
                        审批
                    </el-button>
                    <el-button
                        v-if="
                            routePath == '/reimbRecord' &&
                            btnP.pay &&
                            scope.row.status == 4 &&
                            scope.row.payWay == 1
                        "
                        @click="onPay(scope.row)"
                        style="color: #2370eb"
                        type="text"
                    >
                        支付确认
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { expenseList, reimbUpdate } from '@/api/reimb/reimb';
import { declareGet } from '@/api/cost/declare.js';
import Details from '../details/details.vue';
import DeclareDetails from '../../declare/detail/detail.vue';
import { getBrief } from '@/api/reimb/reimb';
import { dataTool } from 'echarts';
export default {
    components: {
        Details,
        DeclareDetails,
    },
    data() {
        return {
            data: {},
            tableData: [],
            btnP: {},
            tableRowId: '',
            searchDate: [],
            dialogApproval: false,
            direction: 'rtl',
            dialogRevoke: false,
            drawerUpData: false,
            dialogDetails: false,
            dialogDeclareDetails: false,
            adminId: sessionStorage.getItem('adminId'),
            userType: sessionStorage.getItem('userType'),
            detailsType: false,
            detailsTypes: false,
            searchVal: {},
            routePath: this.$route.path,
        };
    },
    methods: {
        // 获取列表
        getData(data, btnP) {
            this.tableData = data;
            this.btnP = btnP;
        },
        getBriefData() {
            let data = {
                param: {
                    status: 4,
                },
            };
            if (
                this.userType == 1 ||
                this.userType == 2 ||
                this.userType == 3 ||
                this.userType == 4
            ) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (
                this.userType == 8 ||
                this.userType == 9 ||
                this.userType == 10
            ) {
                data.param.departmentId = Number(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (this.searchVal.adminId) {
                data.param.adminId = this.searchVal.adminId;
            }
            if (this.searchVal.departmentId) {
                data.param.departmentId = Number(this.searchVal.departmentId);
            }
            getBrief(data).then((res) => {
                // console.log(res);
                if (res.data) {
                    this.$emit('amountData', res.data.amount);
                } else {
                    this.$emit('amountData', 0);
                }
            });
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },

        // 审核
        onApproval(row) {
            // console.log(123);
            this.$emit('onAudit', row);
            // this.dialogDetails = false;
            // this.detailsType = true;
            // this.detailsTypes = true;
            // this.dialogApproval = true;
            // console.log(this.dialogApproval);
            // setTimeout(() => {
            //     this.$refs.approval.getData(row);
            // });
        },
        // 撤销
        onRevoke(row) {
            this.dialogRevoke = true;
            this.data = row;
        },
        declareUpdate() {
            let data = {
                param: {
                    id: this.data.id,
                    status: 9,
                },
            };
            reimbUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('撤销成功');
                    this.$emit('getData');
                    this.dialogRevoke = false;
                }
            });
        },
        // 详情
        onDetails(row) {
            this.$emit('onDetails', row);
            // this.dialogDetails = true;
            // this.detailsType = true;
            // setTimeout(() => {
            //     this.$refs.details.getData(row, this.userType);
            // });
        },

        // 重新编辑
        onEdit(rwo) {
            this.drawerUpData = true;
            setTimeout(() => {
                this.$refs.upData.getData(rwo);
            });
        },
        onPay(row) {
            this.$emit('onPay', row);
        },
        // 申报详情
        onDeclareDetails(row) {
            this.$emit('onDeclareDetails', row);
        },
        onDeclareDetail(row) {
            this.detailsType = false;
            let data = {
                param: {
                    id: row.declareId,
                },
            };
            declareGet(data).then((res) => {
                this.dialogDeclareDetails = true;
                setTimeout(() => {
                    this.$refs.declareDetails.$data.declareDetail = res.data;
                }, 0);
            });
        },
        onDeclareDetailApproval(row) {
            this.detailsTypes = false;
            let data = {
                param: {
                    id: row.declareId,
                },
            };
            declareGet(data).then((res) => {
                this.dialogDeclareDetails = true;
                setTimeout(() => {
                    this.$refs.declareDetails.$data.declareDetail = res.data;
                }, 0);
            });
        },
        handleClose(row) {
            this.dialogApproval = false;
            this.dialogRevoke = false;
            this.drawerUpData = false;
            this.dialogDetails = false;
            this.$emit('getData');
        },
        handleDeclare() {
            // console.log(this.detailsType);
            // console.log(this.detailsTypes);
            if (!this.detailsType) {
                this.dialogDetails = true;
            }
            this.dialogDeclareDetails = false;
            this.$emit('getData');
        },

        openFile(url, type) {
            if (
                type == 'png' ||
                type == 'jpg' ||
                type == 'jpeg' ||
                type == 'pdf'
            ) {
                window.open(url);
                return;
            }
            window.open(
                'https://view.officeapps.live.com/op/view.aspx?src=' + url
            );
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    background: #fff;
}
.dialog_info {
    width: 80%;
    text-align: center;
    margin: 25px auto 150px;
    font-size: 14px;
    color: #333333;
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
.file_list {
    cursor: pointer;
}
.file_list + .file_list {
    margin-top: 8px;
}
</style>
